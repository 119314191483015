<template>
  <div
    class="
      flex flex-col
      px-4
      lg:px-6
      lg:overflow-y-scroll
      lg:h-screen
      pb-10
      mt-20
      lg:mt-0
    "
  >
    <Steps
      ref="stepRef"
      :items="steps"
      :step-clicked="stepChanged"
      :stepIndex="selectedStepIndex"
    />
    <div class="w-full">
      <div class="py-10 max-w-7xl mx-auto">
        <div class="grid grid-cols-3 gap-4">
          <div
            class="row-same-height left-block-main hidden sm:block"
            v-if="displaySummary ? selectedStepIndex + 1 < steps.length : true"
          >
            <HelpView
              v-bind="{
                label: 'Need help? Click on Ed!',
                helpHtml,
              }"
            />
          </div>
          <div
            class="col-span-3 sm:col-span-2 h-full overflow-y-scroll"
            v-bind:class="{
              'border-l border-white sm:border-gray-300': displaySummary
                ? selectedStepIndex + 1 < steps.length
                : true,
            }"
          >
            <ApplicationForm
              ref="formRef"
              :form="steps[selectedStepIndex]"
              class=""
              @onFocus="(html) => (helpHtml = html)"
            />
            <div
              class="
                flex
                justify-end
                sm:justify-start
                pr-4
                sm:pr-0
                pl-10
                pt-4
                pb-2
                space-x-3
                bottom-2
                right-2
              "
              v-if="
                displaySummary ? selectedStepIndex + 1 < steps.length : true
              "
            >
              <Button
                variant="secondary"
                size="lg"
                content="Back"
                :disabled="selectedStepIndex === 0"
                :click="previousClicked"
              />

              <Button
                v-if="selectedStepIndex + 1 === steps.length"
                variant="themed"
                size="lg"
                content="Submit"
                :click="submitForm"
                :is-loading="isLoading"
              />
              <Button
                v-else
                variant="themed"
                size="lg"
                content="Next"
                :click="nextClicked"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Steps from "../../molecules/Steps/Simple/Simple.vue";
import Button from "../../atoms/Button/Button.vue";
import { ref } from "vue";
import ApplicationForm from "../../organisms/FormLayouts/ApplicationForm/ApplicationForm.vue";
import Summary from "../../organisms/Summary/Summary.vue";
import HelpView from "@/components/organisms/ContentSections/HelpView/HelpView";

export default {
  components: {
    HelpView,
    Steps,
    ApplicationForm,
    Button,
    Summary,
  },
  mounted() {
    this.setFormData(this.formData);
  },
  watch: {
    formData: function (data) {
      this.setFormData(data);
    },
    items: function (val) {
      this.steps = val;
    },
  },
  data() {
    return {
      helpHtml: this.steps[this.selectedStepIndex]?.questions[0]?.helpBlurb,
    };
  },
  methods: {
    setFormData(data) {
      const form = this.$refs?.formRef,
        booleanObj = {};
      if (data && form) {
        if (form.setFormObject) form.setFormObject({ ...data });
      }
    },
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    next: {
      type: Function,
      default: () => {},
    },
    previous: {
      type: Function,
      default: () => {},
    },
    stepChange: {
      type: Function,
      default: () => {},
    },
    submit: {
      type: Function,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => null,
    },
    isSummary: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const steps = ref(props.items);
    const selectedStepIndex = ref(0);
    const stepRef = ref();
    const formRef = ref();
    let surveyAnswersMap = {};
    const stepChanged = (itemsArray, index) => {
      steps.value = itemsArray;
      selectedStepIndex.value = index;
      surveyAnswersMap = formRef.value.getFormObject();
      props.stepChange(surveyAnswersMap);
      // steps.value[2].status = "pending";
    };
    const nextClicked = () => {
      try {
        surveyAnswersMap = formRef.value.getFormObject();
        stepRef.value.nextClicked();
        props.next({ ...surveyAnswersMap, index: selectedStepIndex.value });
      } catch (e) {
        console.log(e);
      }
    };
    const previousClicked = () => {
      surveyAnswersMap = formRef.value.getFormObject();
      stepRef.value.previousClicked();
      props.previous(surveyAnswersMap);
    };
    const submitForm = () => {
      console.log("submit");
      surveyAnswersMap = formRef.value.getFormObject();
      props.submit({ ...surveyAnswersMap });
    };

    return {
      steps,
      stepRef,
      formRef,
      stepChanged,
      nextClicked,
      previousClicked,
      submitForm,
      selectedStepIndex,
    };
  },
};
</script>

<style lang="css" scoped></style>
